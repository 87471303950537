<template>
  <div>
    <b-card class="col-12 col-md-3 w-50">
      <div>
        <b-overlay :show="show" rounded="sm">
          <statistic-card-vertical
            icon="UsersIcon"
            :statistic="subscribersGained.toString()"
            statistic-title="User"
            color="info"
        /></b-overlay>
      </div>
    </b-card>
    <b-card title="ประวัติผู้ใช้งาน">
      <b-button size="sm" variant="success" @click="exportExcel">
        ExportExcel
      </b-button>
      <b-row>
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              @change="_limit"
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="8" class="my-1">
          <b-form-group
            label="Sort"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
                <template v-slot:first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-card-text>
        <b-overlay :show="show" rounded="sm">
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <!-- View -->
          </b-table>
        </b-overlay>
        <!-- pagination -->
        <b-col cols="12">
          <b-pagination
            @change="_page"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BButton,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BOverlay,
} from "bootstrap-vue";
import API from "@/views/API/condb.js";
import axios from "axios";
import _ from "lodash";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import { rawListeners } from "process";

export default {
  components: {
    BCard,
    BCardText,
    StatisticCardVertical,
    BTable,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BOverlay,
  },
  data() {
    return {
      perPage: 25,
      pageOptions: [5, 10, 15, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      show: true,
      fields: [
        // { key: "sequence", label: "Number", sortable: false },
        { key: "username", label: "UserName", sortable: true },
        { key: "deviceId", label: "DeviceId", sortable: true },
        { key: "timeStamp", label: "Date Time", sortable: false },
      ],
      items: [],
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
      subscribersGained: {},
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  created() {
    this.getData();
    if (
      _.isEmpty(localStorage.getItem("access_token")) ||
      _.isEmpty(localStorage.getItem("access_user"))
    ) {
      localStorage.removeItem("access_user");
      localStorage.removeItem("access_token");
      this.$router.push({ name: "login" });
    }
  },
  methods: {
    info(item, index, button) {
      console.log(item);
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering

      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    _page() {
      this.getData();
    },
    _limit() {
      this.getData();
    },
    exportExcel() {
      saveExcel({
        data: this.items,
        fileName: "ประวัติผู้ใช้งาน",
        columns: [
          {
            title: "ประวัติผู้ใช้งาน",
            headerCellOptions: { textAlign: "center" },
            children: [
              {
                title: "Username",
                field: "username",
              },
              {
                title: "DeviceID",
                field: "deviceId",
              },
              {
                title: "Date Time",
                field: "timeStamp",
              },
            ],
          },
        ],
      });
    },
    async getData() {
      const { access_token } = await this.access_token();
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const { userId } = await JSON.parse(localStorage.getItem("access_user"));
      const mid = userId;
      const url = `${API}logLogin/${mid}?_page=1&_limit=100&_sort=-1`;
      const res = await axios.get(url, head);
      const res_total = Math.ceil(Number(res.data.message.total) / 100);

      const url_link = [];
      for (let i = 1; i <= res_total; i++) {
        url_link.push(`${API}logLogin/${mid}?_page=${i}&_limit=100&_sort=-1`);
      }

      let res_data_ = [];
      for (let i in url_link) {
        res_data_ = [
          ...res_data_,
          ...(await axios.get(url_link[i], head)).data.message.result,
        ];
      }

      this.items = res_data_.map((el) => {
        return {
          ...el,
          timeStamp: new Date(el.timeStamp).toLocaleString("th-TH"),
        };
      });
      console.log(this.items);
      this.subscribersGained = await res.data.message.total;
      this.totalRows = await res.data.message.total;
      this.show = false;
    },

    async access_token() {
      return (
        await axios.post(`${API}token`, "", {
          headers: {
            Authorization:
              localStorage.getItem("access_token") &&
              JSON.parse(localStorage.getItem("access_token")),
          },
        })
      ).data.message;
    },
  },
};
</script>

<style>
</style>
